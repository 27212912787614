#aktenkunde .light {
    color: #777;
    font-style: italic;
    margin-left: 10px;
    font-size: 0.8em;
    margin-bottom: 1em;
}


#aktenkunde ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
}

#aktenkunde .gallery {
    display: flex;
}

#aktenkunde .gallery div {
    margin: 10px;
    height: 150px;
    width: 100px;
    border: 1px solid;
    border-color: #02427f;
    border-radius: 5px;
    overflow: hidden;
}

#aktenkunde .gallery-item {
    position: absolute;
    margin: auto;
    border-left: 1px solid;
    border-right: 0px !important;
    border-top: 0px !important;
    border-bottom: 0px !important;
    border-color: #02427f;
    border-radius: 5px;
    z-index: 999;
    min-width: 100%;
    min-height: 100%;
}